<template>
  <auth>
    <div class="right-content" id="main-content">
      <div class="login-header">
        <div class="brand">
          <div class="img-responsive">
            <img src="/logo.jpg" alt="CoOperative BEEDEE" />
          </div>
          <div class="brand-name"><span>Co-op</span><span>Bid</span></div>
        </div>
        <div class="icon">
          <i class="fa fa-sign-in"></i>
        </div>
      </div>
      <div class="login-content">
        <h4>Register</h4>
        <hr />
        <form class="margin-bottom-0" @submit.prevent="register" id="register-form">
          <div class="form-group m-b-15">
            <input
              v-model="user.email"
              autocomplete="email"
              class="form-control"
              placeholder="Email Address"
              required
              type="email"
              name="email"
            />
          </div>
          <div class="form-group m-b-15">
            <input
              v-model="user.password"
              class="form-control"
              placeholder="Password"
              autocomplete="new-password"
              required
              type="password"
              name="password"
            />
          </div>
          <div class="form-group m-b-15">
            <input
              v-model="user.password_confirmation"
              class="form-control"
              placeholder="Confirm Password"
              autocomplete="new-password"
              required
              type="password"
              name="password_confirmation"
            />
          </div>
          <div class="login-buttons">
            <button class="btn btn-primary btn-lg btn-block">
              Register
            </button>
          </div>
        </form>

        <div class="login-buttons">
          <div class="text-center mx-2"><small>OR</small></div>
          <g-signin-button
            class="btn-outline-primary btn btn-block btn-lg"
            :params="googleSignInParams"
            @error="onSignInError"
            @success="onSignInSuccess"
          >
            <span class="icon" /> Sign in with Google
          </g-signin-button>
        </div>

        <div class="m-t-20 m-b-40 p-b-40 text-inverse">
          <router-link :to="{ name: 'Login' }" class="link">
            Have an account? Sign In.
          </router-link>
        </div>
      </div>
    </div>
  </auth>
</template>

<script>
import Auth from "@/components/Auth.vue";

export default {
  components: {
    Auth,
  },
  data() {
    return {
      app: process.env.VUE_APP_NAME,
      user: {
        email: "",
        password: "",
        access_token: "",
      },
      error: "",
      googleSignInParams: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      },
    };
  },
  computed: {
    loggeduser() {
      return this.$store.getters.getAuthUser;
    },
  },
  methods: {
    register() {
      this.$form.load('register-form')
      this.$axios
        .post("/api/v1/register", this.user)
        .then(() => {
          this.$auth.setVerifyUser(this.user.email);
          window.location.href = this.$router.resolve({
            name: "register.confirm",
          }).href;
        })
        .catch((error) => {
          this.$form.validate('register-form', error.response)
        });
    },

    onSignInSuccess(googleUser) {
      const profile = googleUser.getBasicProfile();
      this.email = profile.getEmail();
      let auth = googleUser.getAuthResponse(true);
      this.access_token = auth.access_token;
      this.googleLogin();
    },
    onSignInError() {
      this.error = { status: 401, data: { message: "Google sign in failed" } };
    },
    googleLogin() {
      let self = this;
      this.$axios
        .post("api/v1/auth/google", {
          access_token: self.access_token,
        })
        .then((response) => {
          this.navigateLogin(response.data);
        })
        .catch(() => {
          this.error = {
            status: 401,
            data: { message: "Could not register you in at this time" },
          };
        });
    },
    navigateLogin(data) {
      this.$auth.setToken(data.access_token);
      this.$axios.defaults.headers.Authorization =
        "Bearer " + this.$auth.getToken();
      this.$store.dispatch("setAuthUser", data).then(() => {
        if (this.loggeduser.requires_password_change) {
          this.$router.replace({ name: "set-password" });
          return;
        }

        if (
          this.loggeduser.id_number == null &&
          !this.loggeduser.create_by_admin
        ) {
          this.$router.replace({ name: "verify.personal" });
          return;
        }

        if (
          !this.loggeduser.verified_phone &&
          this.loggeduser.id_number !== null
        ) {
          this.$router.replace({ name: "verify.phone" });
          return;
        }

        if (this.loggeduser.verified_phone) {
          this.$router.replace({ name: "dashboard" });
        }
      });
    },
  },
};
</script>
